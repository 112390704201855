import type { Environment } from 'types/environment'
import type { Pattern } from '~/types/validation'
import { isNumber } from 'types/generic/data-type.guards'
import { emailRegex } from '~/data/patterns/email'
import { latinRegex } from '~/data/patterns/latin'
import { mustContainNumbersRegex } from '~/data/patterns/mustContainNumbers'
import { phoneRegex } from '~/data/patterns/phone'
import { postCodes } from '~/data/patterns/postcodes'

type StaticValidator<Value = string | number | boolean> = (value: Value) => boolean
type DynamicValidator<RuleType = string | number | boolean, ValueType = RuleType> = (
  rule: RuleType,
  value: ValueType
) => boolean

interface Validators {
  isRequired: StaticValidator<boolean | string | number>
  isMin: DynamicValidator<number, string | number>
  isMax: DynamicValidator<number, string | number>
  isEmail: StaticValidator<string>
  isPhone: StaticValidator<string>
  conformsToPattern: DynamicValidator<Pattern, string>
  isPostCode: DynamicValidator<Environment.CountryCode, string>
  isLatin: StaticValidator<string>
  isLatinAndContainsNumber: StaticValidator<string>
}

export const validators: Validators = {
  isMin(rule, value) {
    if (isNumber(value))
      return value >= rule

    return value.length >= rule
  },
  isMax(rule, value) {
    return isNumber(value) ? value <= rule : value.length <= rule
  },
  isRequired(value) {
    return !!value
  },
  isEmail(value: string) {
    return emailRegex.test(value)
  },
  isPhone(value: string) {
    return phoneRegex.test(value)
  },
  isLatin(value: string) {
    return latinRegex.test(value)
  },
  isLatinAndContainsNumber(value: string) {
    return latinRegex.test(value) && mustContainNumbersRegex.test(value)
  },
  conformsToPattern({ regexp }: Pattern, value: string) {
    const re = new RegExp(regexp)
    return re.test(value)
  },
  isPostCode(countryCode, value) {
    const postcode = postCodes[countryCode]
    if (!postcode)
      return Boolean(value)

    const re = new RegExp(postcode.regexp)
    return re.test(value)
  },
}
