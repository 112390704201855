import type { Environment } from 'types/environment'
import type { ShippingMethod, ShippingMethodName } from 'types/models/cart.model'

export const useShippingStore = defineStore('ShippingStore', () => {
  const loading = ref<boolean>(false)
  const selected = ref<ShippingMethod | undefined>(undefined)
  const methods = ref<ShippingMethod[]>([])

  // const { $pinia } = useNuxtApp()
  // TODO: Check why $cart cannot be deconstructed
  const context = useNuxtApp()
  const storefrontStore = useStorefrontStore()
  const checkoutStore = useCheckoutStore()
  const cartStore = useCartStore()

  const addShippingMethodToCart = async (id: ShippingMethodName) => {
    try {
      const cart = await context.$cart.selectShippingMethod(id)

      selected.value = cart.shippingMethod
      cartStore.updateCart(cart)
    }
    catch (e) {
      return Promise.reject(e)
    }
  }

  const fetchAll = async (countryCode?: Environment.CountryCode) => {
    try {
      loading.value = true
      const { currentMarketCountryCode } = storefrontStore
      const { shipping } = checkoutStore.information.address
      countryCode = countryCode || shipping.country || currentMarketCountryCode

      const shippingMethods = await context.$cart.listShippingMethods(countryCode)

      if (shippingMethods.length)
        methods.value = shippingMethods

      if (!selected.value?.id && shippingMethods[0]) {
        const id = shippingMethods[0].id
        await addShippingMethodToCart(id)
      }
      return Promise.resolve()
    }
    catch (e) {
      return Promise.reject(e)
    }
    finally {
      loading.value = false
    }
  }

  return {
    loading,
    selected,
    methods,
    fetchAll,
    addShippingMethodToCart,
  }
})
