import type { Environment } from 'types/environment'
import type { CountryCode } from 'types/generic/geographic'
import type { ShippingMethodName } from 'types/models/cart.model'
import type { Section } from 'types/models/section'

// https://docs.brinkcommerce.com/models

// TODO: Merge CartItem & ShippingOption as they're both products
interface sizeFilterValue {
  value: '36-40'
  key: 'size'
}
type possibleFilterValues =
  | {
    key: string
    value: string
  }
  | sizeFilterValue

type marketCodes = 'NLWHS01M'

interface DiscountedCartItem {
  id: string
  rowId: string
  discountAmount: {
    [key: string]: number
  }
}

export interface Discounts {
  rules: {
    id: string // "discount-code-id"
    ruleData: {
      discountCode: string // "summer2020"
    }
    ruleType: 'DISCOUNTCODE' | string
  }[]
  orderRules: {
    [key: string]: any
    freeShipping: boolean
  }[]
  productDiscounts: DiscountedCartItem[]
  cartDiscount: {
    [key: string]: number
  }
}
export interface CartItem {
  id: string
  productId: string
  rowId: string
  quantity: number
  addons: []
  added: Date
  updated: Date
  relatedProduct: string // This is the parent id
  attribute: Record<string, unknown>
  customerAttribute: {
    'ean'?: string
    'upc'?: string
    'size': string
    'skuNumber': string
    'channelStatus': string | 'enabled' | 'disabled'
    'variantCode': string
    'activeOnMarket': marketCodes[]
    // To be verified attributes
    'itemComposition'?: {
      [key in Environment.StorefrontCode]: string
    }
    'itemType': 'Global'
    'itemProductFeedLabel': string
    'itemFBA': null
    'itemSections': Section[]
    'taxjar:product_tax_code'?: string
  }
  name: string
  category: 'Socks' | 'All Products'
  slug: string
  parentSlug: string
  type: 'productVariant' | 'shippingOption'
  // currency: string
  price: {
    [key in Environment.Currency['code']]?: number
  }
  discount: {
    [key in Environment.Currency['code']]?: number
  }
  isGift: boolean
  // Sizes, colors, etc
  filterAttributes: possibleFilterValues[]
  options: []
  imageUrl: string | null
}

export interface Cart {
  id?: string
  state?: 'ACTIVE' | 'CLOSED'
  created?: Date
  lastUpdated?: Date
  cartItems: CartItem[]
  totalPriceWithDiscount: number
  totalDiscountAmount: number
  totalPrice: number
  discounts: Discounts | null
  isFreeShippingDiscountCodeApplied: boolean // TODO - S Is this a lie?
  store: {
    countryCode?: Uppercase<CountryCode>
    currencyUnit?: Environment.Currency['code']
    languageCode?: string
    taxPercentage?: number
  }
}

export interface getCartResponse extends Cart {
  totalShippingAmount: number
}

export interface Error {
  error: string
  requestId: string
  code: string | keyof typeof BrinkPaymentErrorCodes
  message: string
}

export interface CartSessionResponse {
  jwtToken: string
  cart: Cart
}

/**
 * {@link https://docs.brinkcommerce.com/integrations#post-paymentsadyenpaymentmethods}
 */
export interface PaymentMethodsRequestBody {
  amount: {
    currency: string
    value: number
  }
  countryCode: Uppercase<CountryCode>
  channel: 'ios' | 'android' | 'web'
  blockedPaymentMethods: string[]
  languageCode: string
  shopperReference?: string // Required for recurring payments, example user ID or account ID.
}

/**
 * {@link https://docs.brinkcommerce.com/integrations#adyen-sessions}
 *
 * {@link http://swagger.brinkcommerce.com.s3-website-eu-west-1.amazonaws.com/order/#/default/post_carts_adyen_orders}
 */
export interface AdyenSessionRequestBody {
  shippingAddress: Address
  billingAddress: Address
  returnUrl: string

  storePaymentMethod?: boolean
  shopperReference?: string
  email: string
  languageCode?: string
  blockedPaymentMethods?: [string]
}

/**
 * {@link https://docs.brinkcommerce.com/integrations#adyen-sessions}
 */
export interface AdyenSessionResponse {
  adyen: {
    session: AdyenSession
  }
  brink: {
    order: Order
  }
}

/**
 * {@link https://docs.brinkcommerce.com/integrations#adyen-sessions}
 *
 * {@link http://swagger.brinkcommerce.com.s3-website-eu-west-1.amazonaws.com/order/#/default/post_payments_adyen_paymentmethods}
 */
export interface PostAdyenPaymentMethodsRequest {
  amount:	{
    currency: string
    value:	number
  }
  countryCode: string
  channel:	string
  blockedPaymentMethods:	[string]
  languageCode: string
  shopperReference: string
}

/**
 * {@link https://docs.brinkcommerce.com/integrations#adyen-sessions}
 *
 * {@link http://swagger.brinkcommerce.com.s3-website-eu-west-1.amazonaws.com/order/#/default/post_payments_adyen_paymentmethods}
 */
export interface AdyenPaymentRequestBody {
  redirect: {
    canceled: string
    default: string
    success: string
    error: string
  }
  orderId: string
  origin: string
  paymentMethod: object
  shopperLocale: string
  browserInfo?: object
  shopperReference?: string
  email: string
  shippingAddress: OverrideAddress
  billingAddress: OverrideAddress
}

export interface OverrideAddress {
  country?: Environment.CountryCode
  city: string
  phone: string
  streetAddress: string
  houseNumberOrName: string
  familyName: string
  postalCode: string
  givenName: string
  region: string
}

/**
 * {@link https://docs.brinkcommerce.com/integrations#adyen-sessions}
 *
 * {@link http://swagger.brinkcommerce.com.s3-website-eu-west-1.amazonaws.com/order/#/default/post_carts_orders}
 */
export interface AdyenCartToAdyenOrderBody {
  shippingAddress: {
    country: Environment.CountryCode
    city: string
    phone: string
    streetAddress: string
    houseNumberOrName: string
    familyName: string
    postalCode: string
    givenName: string
    region: string
  }
  blockedPaymentMethods?: [
    string,
  ]
  billingAddress: {
    country: Environment.CountryCode
    city: string
    phone: string
    streetAddress: string
    houseNumberOrName: string
    familyName: string
    postalCode: string
    givenName: string
    region: string
  }
  languageCode?: string
  returnUrl?: string
  email: string
  shopperReference?: string
}

/**
 * {@link https://docs.brinkcommerce.com/integrations#adyen-sessions}
 *
 * {@link http://swagger.brinkcommerce.com.s3-website-eu-west-1.amazonaws.com/order/#/default/post_payments_adyen_paymentmethods}
 */
export interface PostAdyenPaymentMethodsResponse {
  paymentMethods: [
    {
      brands: [
        string,
      ]
      configuration: object
      name: string
      fundingSource: string
      type: string
      issuers: [
        {
          name: string
          disabled: true
          id: string
        },
      ]
      brand: string
      group: {
        paymentMethodData: string
        name: string
        type: string
      }
    },
  ]
  storedPaymentMethods: [
    {
      lastFour: string
      holderName: string
      expiryMonth: string
      networkTxReference: string
      shopperEmail: string
      expiryYear: string
      type: string
      ownerName: string
      iban: string
      name: string
      supportedShopperInteractions: [
        string,
      ]
      id: string
      brand: string
    },
  ]
}

/**
 * {@link https://docs.brinkcommerce.com/integrations#adyen-sessions}
 *
 * {@link http://swagger.brinkcommerce.com.s3-website-eu-west-1.amazonaws.com/order/#/default/post_payments_adyen_paymentmethods}
 */
export interface PostAdyenPaymentResponse {
  paymentResult: {
    threeDS2Result: {
      whiteListStatus: string
      exemptionIndicator: string
      challengeCancel: string
      eci: string
      cavvAlgorithm: string
      challengeIndicator: string
      dsTransID: string
      transStatusReason: string
      riskScore: string
      messageVersion: string
      authenticationValue: string
      transStatus: string
      threeDSServerTransID: string
      timestamp: string
    }
    amount: {
      currency: string
      value: number
    }
    resultCode: string
    fraudResult: {
      accountScore: number
      results: [
        {
          accountScore: number
          name: string
          checkId: number
          merchantReference: string
        },
      ]
    }
    donationToken: string
    refusalReasonCode: string
    threeDSPaymentData: string
    paymentMethod: {
      type: string
      brand: string
    }
    threeDS2ResponseData: {
      acsTransID: string
      exemptionIndicator: string
      cardHolderInfo: string
      sdkEphemPubKey: string
      acsURL: string
      dsReferenceNumber: string
      acsReferenceNumber: string
      cavvAlgorithm: string
      challengeIndicator: string
      dsTransID: string
      transStatusReason: string
      acsOperatorID: string
      authenticationType: string
      riskScore: string
      messageVersion: string
      acsSignedContent: string
      acsChallengeMandated: string
      transStatus: string
      threeDSServerTransID: string
    }
    action: object
    refusalReason: string
    additionalData: object
    pspReference: string
    order?: Order
  }
  order: Order
}

/**
 * {@link https://docs.brinkcommerce.com/integrations#adyen-sessions}
 *
 * {@link http://swagger.brinkcommerce.com.s3-website-eu-west-1.amazonaws.com/order/#/default/post_carts_orders}
 */
export interface PostAdyenOrderResponse {
  refundFee: true
  externalTaxRate: number
  discount: string
  orderDiscountAmount: number
  reference: number
  cart: {
    totalPriceWithDiscount: number
    shipping: {
      price: {
        additionalProp1: number
        additionalProp2: number
        additionalProp3: number
      }
      name: string
      customerAttribute: object
      id: string
      type: string
    }
    discounts: {
      cartDiscount: {
        additionalProp1: number
        additionalProp2: number
        additionalProp3: number
      }
      orderRules: [
        {
          minItems: number
          itemIds: [
            string,
          ]
          giftIds: [
            string,
          ]
          id: string
          type: string
        },
      ]
    }
    totalPrice: number
    totalDiscountAmount: number
    reservationQuantityLimit: number
    id: string
    store: {
      countryCode: string
      taxPercentage: number
      languageCode: string
      taxPercentageDecimals: number
      currencyUnit: string
    }
    cartItems: CartItem[]
    expiryDateTime: Date
    totalShippingAmount: number
  }
  taxIncludedPricing: true
  lastUpdated: string
  orderAmount: number
  orderLines: [
    OrderLine & {
      imageUrl: string
      priceUnit: string
      priceUnitQuantity: number
      taxRateDecimals: number

      relatedOrderLine?: string
    },
  ]
  countryCode: string
  orderRules: [
    {
      minItems: number
      discountInPercentage: number
      atLeastOrderValue: {
        amount: number
        currencyUnit: string
      }
      itemIds: [
        string,
      ]
      discountInFixedPrice: {
        amount: 0
        currencyUnit: string
      }
      giftIds: [
        string,
      ]
      id: string
      type: string
      atMostOrderValue: {
        amount: number
        currencyUnit: string
      }
      combine: boolean
    },
  ]
  customerAttribute: object
  id: string
  orderTaxAmount: number
  currencyUnit: Environment.Currency['code']
  redirect: {
    canceled: string
    default: string
    success: string
    error: string
  }
  created: string
  paymentReference: string
  cartId: string
  statusLog: [
    {
      status: string
      timestamp: string
    },
  ]
  store: string
  potentialFraud: boolean
  paymentProvider: string
  orderAmountWithDiscount: number
  person?: {
    country: string
    city: string
    houseNumberOrName: string
    created: Date
    postalCode: string
    givenName: string
    lastUpdated: Date
    phone: string
    streetAddress: string
    familyName: string
    id: string
    region: string
    email: string
  }
  shippingAddress: CartOrderAddress
  billingAddress: CartOrderAddress
  paymentMethod: string
  personId: string
  refundActive: true
  failedReason: string
  status: string
}

/**
 * {@link https://docs.brinkcommerce.com/integrations#adyen-sessions}
 *
 * {@link http://swagger.brinkcommerce.com.s3-website-eu-west-1.amazonaws.com/order/#/default/get_carts}
 */
export interface AdyenGetCartResponse {
  totalPriceWithDiscount: number
  shipping: {
    price: {
      additionalProp1: number
      additionalProp2: number
      additionalProp3: number
    }
    name: string
    customerAttribute: object
    id: string
    type: string
  }
  discounts: {
    cartDiscount: {
      additionalProp1: number
      additionalProp2: number
      additionalProp3: number
    }
    orderRules: [
      {
        minItems: number
        itemIds: [
          string,
        ]
        giftIds: [
          string,
        ]
        id: string
        type: string
      },
    ]
  }
  totalPrice: number
  totalDiscountAmount: number
  reservationQuantityLimit: number
  id: string
  store: {
    countryCode: string
    taxPercentage: number
    languageCode: string
    taxPercentageDecimals: number
    currencyUnit: string
  }
  cartItems: CartItem[]
  expiryDateTime: Date
  totalShippingAmount: number
}

export interface AdyenSession {
  id: string
  sessionData: string
}

export interface ShippingOption {
  active: number
  archived: number
  attribute: {
    // !Change with locale codes
    description?: {
      [key: string]: string
    }
    shortdescription?: {
      [key: string]: string
    }
    fulldescription?: {
      [key: string]: string
    }
    displayName?: {
      [key: string]: string
    }
  }
  category: 'shipping'
  created: Date
  customerAttributes: []
  description: string
  discount: { [key in Environment.Currency['code']]?: number }
  filterAttributes: []
  id: ShippingMethodName
  imageUrl: string
  images: string[]
  lastUpdated: Date
  name: string
  parentSlug: string
  price: {
    [key: string]: number
  }
  relatedProduct: string
  sequenceNumber: number
  slug: string
  type: 'shippingOption'
}

export interface ProductStock {
  storeId: Uppercase<Environment.StorefrontCode>
  products: {
    id: string
    validateStock: boolean
    stockQuantity: number
    reservedQuantity: number
    availableQuantity: number
    isAvailable: boolean
  }[]
}

export interface Address {
  country: Environment.CountryCode
  city: string
  phone: string
  streetAddress: string
  houseNumberOrName: string
  familyName: string
  postalCode: string
  givenName: string
  region: string
}

type CartOrderAddress = Address & {
  phone?: string
  houseNumberOrName?: string
  region?: string
  email: string
}

export interface Store {
  countryCode: Uppercase<CountryCode>
  languageCode: string
  currencyUnit: string
  created: string
  lastUpdated: string
  tax: number
  continent?: 'EU' | 'OC' | 'AS' | 'NA' | 'SA' | 'AF' | 'AN'
}
export interface OrderLine {
  id: string
  productId: string
  name: string
  options: any[]
  isGift: boolean
  quantity: number
  taxRate: number
  totalAmount: number
  totalAmountWithDiscount: number
  totalTaxAmount: number
  type: OrderLineType
  price: number
  promotionPrice: number
  externalTaxRate?: number
}
export interface Order {
  id: string
  cartId: string
  reference: number
  orderLines: OrderLine[]
  billingAddress: Address
  shippingAddress: Address
  orderAmount: number
  orderAmountWithDiscount: number // Total?
  orderTaxAmount: number
  orderDiscountAmount: number
  store: string
  countryCode: string
  languageCode: string
  currencyUnit: Environment.Currency['code']
  email: string
  orderRules: any[]
  paymentProviderAccount: string
  paymentProvider?: string
  paymentMethod?: string
  statusLog: StatusLog[]
  status?: 'success' | 'created' | string
  potentialFraud?: boolean
  paymentReference?: string
  originalPaymentReference?: string
  lastUpdated?: string
  created?: string
  personId?: string
  refundActive?: string
  taxIncludedPricing: boolean
  externalTaxRate?: number
  customerAttribute: {
    taxCalculations: TaxCalculations
  }
  failedReason?: string
}

export enum BrinkCartErrorCodes {
  UNAUTHORIZED = 'UNAUTHORIZED',
  CART_NOT_FOUND = 'CART_NOT_FOUND',
  CART_CLOSED = 'CART_CLOSED',
  OUT_OF_STOCK = 'OUT_OF_STOCK',
  MISSING_PRODUCTS = 'MISSING_PRODUCTS', // "error": "Product(s) not found: 7333102442632, 7333102606874, 7333102608397",
}

export enum BrinkPaymentErrorCodes {
  UNAUTHORIZED = 'UNAUTHORIZED',
  MISSING_SHIPPING_OPTION_ERROR = 'MISSING_SHIPPING_OPTION_ERROR',
  TAX_JAR_ERROR = 'TAX_JAR_ERROR',
}

export enum BrinkConfirmOrderFailedReasons {
  CVC_DECLINED = 'CVC Declined',
}

export function isValidStore(store: any): store is Store {
  return ['countryCode', 'languageCode', 'currencyUnit', 'created', 'lastUpdated', 'tax'].every(key =>
    Reflect.has(store, key),
  )
}

type OrderLineType = 'physical' | 'shipment' | 'addon'

interface StatusLog {
  status: string
  timestamp: Date
}

interface TaxAmount {
  amount: number
  currency: string
  precision: number
}

interface TaxItem {
  id: string
  totalTaxAmount: TaxAmount
  taxRate: number
}

interface TaxShipping {
  id: string
  totalTaxAmount: TaxAmount
  taxRate: number
}

interface TaxSource {
  name: string
}

interface TaxCalculations {
  orderTaxAmount: TaxAmount
  taxRate: number
  items: TaxItem[]
  shipping: TaxShipping
  taxSource: TaxSource
}
