import type { Order } from '~/types/models/order'
import { getDefaultStorefront } from '../storefront/storefront'
import { getDefaultAddress } from './address'

export function getDefaultOrder(): Order {
  const storefront = getDefaultStorefront()
  return {
    id: '',
    status: 'unknown',
    items: [],
    itemsCount: 0,
    orderNumber: '',
    priceTax: 0,
    priceProducts: 0,
    priceShipping: 0,
    priceDiscount: 0,
    priceTotal: 0,
    storefront,
    market: storefront.markets[0],
    currency: storefront.markets[0].currency.code,
    payment: {
      status: '',
      method: '',
    },
    address: {
      billing: getDefaultAddress(),
      shipping: undefined,
    },
    shippingMethod: {
      name: '',
      estimatedDeliveryTime: '0',
    },
    contact: {
      firstName: '',
      lastName: '',
      email: '',
      telephone: '',
    },
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    externalTaxAmount:
      0,
  }
}
