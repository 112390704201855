import type { CheckoutInformation } from '~/types/checkout'
import defu from 'defu'
import { DUMMY_ADDRESS, DUMMY_CONTACT } from '~/lib/checkout'

export function cleanCheckoutInformation(data: CheckoutInformation): CheckoutInformation {
  function removeEmptyStrings(obj: Record<string, any>): Record<string, any> {
    return Object.fromEntries(
      Object.entries(obj).filter(([_, value]) => value !== ''),
    )
  }

  const cleanedAddress = {
    billing: removeEmptyStrings(data.address.billing),
    shipping: removeEmptyStrings(data.address.shipping),
    contact: removeEmptyStrings(data.contact),
  }

  const shippingAddress = defu(cleanedAddress.shipping, DUMMY_ADDRESS)
  const billingAddress = defu(cleanedAddress.billing, DUMMY_ADDRESS)
  const contact = defu(cleanedAddress.contact, DUMMY_CONTACT)

  return {
    address: {
      shipping: shippingAddress,
      billing: billingAddress,
      useShippingForBilling: data.address.useShippingForBilling,
    },
    contact,
  }
}
