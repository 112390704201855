import type { OrderInformation } from 'types/checkout'
import { ORDER_SESSION } from 'configuration/global.configuration'
import { getByCountryCode, patchByCountryCode } from 'lib/localStorage'
import { isValidAddress, isValidContact } from 'lib/validation/contact'
import { useApplePay } from '~/composables/useApplePay'
import { Order } from '~/types/models/order'

// import { CartState } from './cart'

// function checkIfOrderIsBlocked(items: CartState['items'] | undefined) {
//   if (!items) return false

//   const blockedItemSkus: string[][] = [[]]

//   const itemSkus = items.map(item => item.parentSku)

//   const shouldBlockOrder = blockedItemSkus.some(blockedItemSku =>
//     itemSkus.every(itemSku => blockedItemSku.includes(itemSku))
//   )

//   return shouldBlockOrder
// }

export const useOrderStore = defineStore('OrderStore', () => {
  const orderLoading = ref(false)
  const session = ref<OrderInformation | undefined>(undefined)
  const expressOrderApplePay = ref<Order | undefined>(undefined)

  const context = useNuxtApp()
  const checkoutStore = useCheckoutStore()
  const storefrontStore = useStorefrontStore()

  const setSession = (value: OrderInformation | undefined) => {
    patchByCountryCode(storefrontStore.currentMarketCountryCode, ORDER_SESSION, value)
    session.value = value
  }

  const setSessionFromLocalStorage = () => {
    session.value = getByCountryCode<OrderInformation>(
      storefrontStore.currentMarketCountryCode,
      ORDER_SESSION,
    )
  }

  function updateSessionPaymentMethod(paymentMethod: string) {
    if (!session.value)
      return

    setSession({
      ...session.value,
      order: {
        ...session.value.order,
        payment: {
          ...session.value.order.payment,
          method: paymentMethod,
        },
      },
    })
  }

  const getAndValidateCheckoutInformation = () => {
    const {
      contact,
      address: { shipping: shippingAddress, useShippingForBilling },
    } = checkoutStore.information
    let { billing: billingAddress } = checkoutStore.information.address

    if (useShippingForBilling)
      billingAddress = shippingAddress

    const { language: languageCode } = storefrontStore.currentMarket

    if (!isValidAddress(shippingAddress))
      throw new EvalError('Invalid or incomplete shipping address')
    if (!isValidAddress(billingAddress))
      throw new EvalError('Invalid or incomplete billing address')
    if (!isValidContact(contact))
      throw new EvalError('Invalid or incomplete contact object')

    return { shippingAddress, billingAddress, languageCode, contact }
  }

  const createOrderSession = async (returnUrl: string): Promise<OrderInformation> => {
    // Activate during fraud attacks to stop the attacker from creating orders
    // const cartStore = useCartStore()
    // const orderIsBlocked = checkIfOrderIsBlocked(cartStore.state.items)

    // if (orderIsBlocked) {
    //   return Promise.reject(new Error())
    // }

    orderLoading.value = true

    try {
      const { shippingAddress, billingAddress, languageCode, contact }
        = getAndValidateCheckoutInformation()

      const { order, session } = await context.$payments.createPaymentSession({
        shippingAddress,
        billingAddress,
        languageCode,
        returnUrl,
        contact,
      })

      const data = {
        order,
        session,
      }

      setSession(data)

      return data
    }
    catch (e) {
      // Most likely there's no cart session
      // if (e.code === UNAUTHORIZED) {
      //   return Promise.reject(e)
      // }

      console.error('Failed to create an Adyen Payment Session', e)
      return Promise.reject(e)
    }
    finally {
      orderLoading.value = false
    }
  }

  /**
   * Retrieve the session and order info from the session storage
   */
  const restoreOrCreateSession = (returnUrl: string): Promise<OrderInformation> => {
    if (session.value)
      return Promise.resolve(session.value)

    return createOrderSession(returnUrl)
  }

  const confirmOrder = async (signature: string, orderId: string) => {
    try {
      const order = await context.$payments.confirmPaymentSession(signature, orderId)

      return order
    }
    catch (error) {
      return Promise.reject(error)
    }
  }

  async function createAdvancedFlowApplePay(expressErrorArray: Ref<string[]>, amount: { currency: string, value: number }, emit: any, orderSignature: globalThis.Ref<any, any>) {
    const applePay = await useApplePay()
    const resp = await applePay.createAdvancedFlowApplePay(expressErrorArray, amount, emit, orderSignature)
    return resp
  }

  async function setUpApplePayExpress(checkout: any, expressErrorArray: Ref<string[]>) {
    const _applePay = await useApplePay()

    const applePay = await _applePay.setUpApplePayExpress(checkout, expressErrorArray)
    return applePay
  }

  return {
    orderLoading,
    session,
    expressOrderApplePay,

    setSession,
    setSessionFromLocalStorage,
    updateSessionPaymentMethod,
    restoreOrCreateSession,
    createOrderSession,
    confirmOrder,
    createAdvancedFlowApplePay,
    setUpApplePayExpress,
  }
})
