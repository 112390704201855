import type { Storefront } from '~/types/models/storefront'
import { getDefaultMarket } from './market'

export function getDefaultStorefront(): Storefront {
  return {
    storefrontCode: 'uk',
    contentLocale: 'en-UK',
    contentKey: 'uk',
    tenantKey: 'UK',
    warehouse: {
      region: 'NL',
      codes: ['nl'],
    },
    markets: [getDefaultMarket()],
    isFocusMarket: true,
  }
}
