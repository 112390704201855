import type { Environment } from 'types/environment'

interface PostCode {
  name: string
  regexp: RegExp
  errorMessage: string
  emptyMessage?: string
  examples: string
}

export const postCodes: Record<Environment.CountryCode, PostCode> = {
  BE: {
    name: 'Belgium',
    regexp: /^[0-9]{4}$/,
    examples: '1234',
    errorMessage: 'We hebben een 4-cijferige postcode nodig.',
    emptyMessage: 'Postcode nodig',
  },
  SE: {
    name: 'Sweden',
    regexp: /^(SE-)?\d{3}[ ,-]?\d{2}$/,
    examples: 'SE-12345, 12345, 123 45, 123-45',
    errorMessage: 'We need a 5-digit postcode',
  },
  ES: {
    name: 'Spain',
    regexp: /^(?:0[1-9]|[1-4]\d|5[0-2])\d{3}$/, // Five digits, first two indicate the province. Third digit: large town, main delivery rounds. Last 2 digits: delivery area, secondary delivery route or link to rural areas.
    examples: '12345',
    errorMessage: 'We need a 5-digit postcode',
  },
  US: {
    name: 'United States of America',
    regexp: /^[0-9]{5}(?:-[0-9]{4})?$/,
    examples: '12345, 12345-6789',
    errorMessage: 'We need a 5-digit or 9-digit postcode',
  },
  NL: {
    name: 'Nederlands',
    regexp: /^\d{4}[ ]?[A-Za-z]{2}$/,
    examples: '1234AB, 1234 AB',
    errorMessage: 'We hebben een 4-cijferige postcode nodig gevolgd door 2 letters',
    emptyMessage: 'Postcode nodig',
  },
  NO: {
    name: 'Norway',
    regexp: /^[0-9]{4}$/,
    examples: '1234',
    errorMessage: 'We need a 4-digit postcode',
  },
  DK: {
    name: 'Denmark',
    regexp: /^[0-9]{4}$/,
    examples: '1234',
    errorMessage: 'We need a 4-digit postcode',
  },
  IT: {
    name: 'Italy',
    regexp: /^\d{5}$/,
    examples: '12345',
    errorMessage: 'We need a 5-digit postcode',
  },
  IE: {
    name: 'Ireland',
    regexp: /.*/,
    examples: '',
    errorMessage: 'Please enter a valid post code',
  },
  AE: {
    name: 'United Arab Emirates',
    regexp: /.*/,
    examples: '',
    errorMessage: 'Please enter a valid post code',
  },
  NZ: {
    name: 'New Zealand',
    regexp: /.*/,
    examples: '',
    errorMessage: 'Please enter a valid post code',
  },
  GB: {
    name: 'United Kingdom',
    regexp:
      /^(([A-Z]{1,2}[0-9][A-Z0-9]?|ASCN|STHL|TDCU|BBND|[BFS]IQQ|PCRN|TKCA) ?[0-9][A-Z]{2}|BFPO ?[0-9]{1,4}|(KY[0-9]|MSR|VG|AI)[ -]?[0-9]{4}|[A-Z]{2} ?[0-9]{2}|GE ?CX|GIR ?0A{2}|SAN ?TA1)$/,
    examples: 'EC1A 1BB, W1A 0AX, M1 1AE',
    errorMessage: 'Please enter a valid post code',
  },
  AU: {
    name: 'Australia',
    regexp: /^\d{4}$/,
    examples: '1234',
    errorMessage: 'We need a 4-digit postcode',
  },
  PT: {
    name: 'Portugal',
    regexp: /^\d{4}([-]\d{3})?$/,
    examples: '1234, 1234-567',
    errorMessage: 'Please enter a valid post code (Examples: 1234, 1234-567)',
  },
  DE: {
    name: 'Germany',
    regexp: /^\d{5}$/,
    examples: '12345',
    errorMessage: 'We need a 5-digit postcode',
  },
  CH: {
    name: 'Switzerland',
    regexp: /^\d{4}$/,
    examples: '1234',
    errorMessage: 'We need a 4-digit postcode',
    emptyMessage: 'Die Postleitzahl fehlt',
  },
  AT: {
    name: 'Austria, Österreich',
    regexp: /^\d{4}$/,
    examples: '1234',
    errorMessage: 'We need a 4-digit postcode',
    emptyMessage: 'Die Postleitzahl fehlt',
  },
  PL: {
    name: 'Poland',
    regexp: /^\d{2}[-]?\d{3}$/,
    examples: '12345, 12-345',
    errorMessage: 'We need a 5-digit postcode',
  },
  HR: {
    name: 'Croatia',
    regexp: /^\d{5}$/,
    examples: '12345',
    errorMessage: 'We need a 5-digit postcode',
  },
  EE: {
    name: 'Estonia',
    regexp: /^\d{5}$/,
    examples: '12345',
    errorMessage: 'We need a 5-digit postcode',
  },
  HU: {
    name: 'Hungary',
    regexp: /^\d{4}$/,
    examples: '1234',
    errorMessage: 'We need a 4-digit postcode',
  },
  LV: {
    name: 'Latvia',
    regexp: /^(LV-)?\d{4}$/,
    examples: 'LV-1234, 1234',
    errorMessage: 'We need a 4-digit postcode',
  },
  LT: {
    name: 'Lithuania',
    regexp: /^(LT-)?\d{5}$/,
    examples: 'LT-12345, 12345',
    errorMessage: 'We need a 5-digit postcode',
  },
  LU: {
    name: 'Luxembourg',
    regexp: /^(L-)?\d{4}$/,
    examples: 'L-1234, 1234',
    errorMessage: 'We need a 4-digit postcode',
  },
  SK: {
    name: 'Slovakia',
    regexp: /^\d{3}[ ]?\d{2}$/,
    examples: '12345, 123-45',
    errorMessage: 'We need a 5-digit postcode',
  },
  SI: {
    name: 'Slovenia',
    regexp: /^(SI-)?\d{4}$/,
    examples: 'SI-1234, 1234',
    errorMessage: 'We need a 4-digit postcode',
  },
  JP: {
    name: 'Japan',
    regexp: /^\d{3}[-]?\d{4}$/,
    examples: '1234567, 123-4567',
    errorMessage: 'We need a 7-digit postcode',
  },
  FI: {
    name: 'Finland, Suomi',
    regexp: /^\d{5}$/,
    examples: '12345',
    errorMessage: 'We need a 5-digit postcode',
  },
  KR: {
    name: 'Korea',
    regexp: /^\d{2,3}[ ,-]?\d{3}$/,
    examples: '123-456; 123456; 12-345; 12345',
    errorMessage: 'We need your postcode here (Examples: 123-456, 123456, 12-345, 12345)',
  },
  CZ: {
    name: 'Czech Republic',
    regexp: /^\d{3}[ ]?\d{2}$/,
    examples: '12345, 123 45',
    errorMessage: 'We need a 5-digit postcode',
  },
  FR: {
    name: 'France',
    regexp: /^\d{5}$/,
    examples: '12345',
    errorMessage: 'We need a 5-digit postcode',
  },
  IL: {
    name: 'Israel',
    regexp: /^\d{7}$/,
    examples: '1234567',
    errorMessage: 'We need a 7-digit postcode',
  },
  SG: {
    name: 'Singapore',
    regexp: /^\d{6}$/,
    examples: '123456',
    errorMessage: 'We need a 6-digit postcode',
  },
}

/* Depricated postcodes / countries

li: {
    name: 'Lichtenstein',
    regexp: /^\d{4}$/,
    examples: '9485, 9486, 9498',
    errorMessage: 'We need a 4-digit postcode',
  },
mt: {
    name: 'Malta',
    regexp: /^[a-zA-Z]{3}[ ]?\d{2,4}$/,
    examples: 'ABC 1234; ABC 123; ABC 12',
    errorMessage: 'Please enter a valid post code (Examples: ABC 1234, ABC 123, ABC 12)',
  },
mc: {
    name: 'Monaco',
    regexp: /^\d{5}$/,
    examples: '98000, 98001',
    errorMessage: 'We need a 5-digit postcode',
  },
bn: {
    name: 'Brunei',
    regexp: /^[a-zA-Z]{2}[ ]?\d{4}$/,
    examples: 'AB1234',
    errorMessage: 'We need your postcode here (Example: AB1234)',
  },
gf: {
    name: 'French Guiana',
    regexp: /^\d{5}$/,
    examples: '12345',
    errorMessage: 'We need a 5-digit postcode',
  },
gp: {
    name: 'Guadeloupe',
    regexp: /^\d{5}$/,
    examples: '12345',
    errorMessage: 'We need a 5-digit postcode',
  },
gg: {
    name: 'Guernsey',
    regexp: /^[a-zA-Z]{2}\d[ ]?\d[a-zA-Z]{2}$/,
    examples: 'AB1 2CD',
    errorMessage: 'We need your postcode here (Example: AB1 2CD)',
  },
is: {
    name: 'Iceland',
    regexp: /^\d{3}$/,
    examples: '123',
    errorMessage: 'We need a 3-digit postcode',
  },
id: {
    name: 'Indonesia',
    regexp: /^\d{5}$/,
    examples: '12345',
    errorMessage: 'We need a 5-digit postcode',
  },
je: {
    name: 'Jersey',
    regexp: /^[a-zA-Z]{2}\d[ ]?\d[a-zA-Z]{2}$/,
    examples: 'AB1 2CD',
    errorMessage: 'We need your postcode here (Example: AB1 2CD)',
  },
za: {
    name: 'South Africa',
    regexp: /^\d{4}$/,
    examples: '1234',
    errorMessage: 'We need a 4-digit postcode',
  },
sz: {
    name: 'Swaziland',
    regexp: /^[a-zA-Z]\d{3}$/,
    examples: 'A123',
    errorMessage: 'We need your postcode here (Example: A123)',
  },
cy: {
    name: 'Cyprus',
    regexp: /^\d{4}$/,
    examples: '1234',
    errorMessage: 'We need a 4-digit postcode',
  },
hk: {
    name: 'Hong Kong',
    regexp: /.*, // it passes all, it's not required, and it has no format
    examples: '',
    errorMessage: '',
  },
ke: {
    name: 'Kenya',
    regexp: /^\d{5}$/,
    examples: '12345',
    errorMessage: 'We need a 5-digit postcode',
  },
mk: {
    name: 'Macedonia',
    regexp: /^\d{4}$/,
    examples: '1234',
    errorMessage: 'We need a 4-digit postcode',
  },
mg: {
    name: 'Madagascar',
    regexp: /^\d{3}$/,
    examples: '123',
    errorMessage: 'We need a 3-digit postcode',
  },
mq: {
    name: 'Martinique',
    regexp: /^\d{5}$/,
    examples: '12345',
    errorMessage: 'We need a 5-digit postcode',
  },
md: {
    name: 'Moldova',
    regexp: /^\d{4}$/,
    examples: '1234',
    errorMessage: 'We need a 4-digit postcode',
  },
ma: {
    name: 'Morocco',
    regexp: /^\d{5}$/,
    examples: '12345',
    errorMessage: 'We need a 5-digit postcode',
  },
mp: {
    name: 'Northern Mariana Islands',
    regexp: /^\d{5}$/,
    examples: '12345',
    errorMessage: 'We need a 5-digit postcode',
  },
ph: {
    name: 'Philippines',
    regexp: /^\d{4}$/,
    examples: '1234',
    errorMessage: 'We need a 4-digit postcode',
  },
re: {
    name: 'Réunion',
    regexp: /^\d{5}$/,
    examples: '12345',
    errorMessage: 'We need a 5-digit postcode',
  },
th: {
    name: 'Thailand',
    regexp: /^\d{5}$/,
    examples: '12345',
    errorMessage: 'We need a 5-digit postcode',
  },
vi: {
    name: 'U.S. Virgin Islands',
    regexp: /^\d{5}$/,
    examples: '12345',
    errorMessage: 'We need a 5-digit postcode',
  },
uy: {
    name: 'Uruguay',
    regexp: /^\d{5}$/,
    examples: '12345',
    errorMessage: 'We need a 5-digit postcode',
  },
*/
