/* Types for our cart store and repository */

import type { Environment } from 'types/environment'
import type { StockStatus } from './product'
import type { Section } from './section'
import { SHIPPING_METHODS } from 'configuration/global.configuration'

export type ShippingMethodName = (typeof SHIPPING_METHODS)[number]

export interface Cart {
  cartId?: string
  items: CartItem[]
  messages: CartMessage[]
  shippingMethod?: ShippingMethod
  priceTax: number
  itemsCount: number
  priceProducts: number
  priceShipping: number
  priceDiscount: number
  priceTotal: number
  discountCode: string
  isFreeShippingDiscountCodeApplied: boolean
  countryCode: Environment.CountryCode | undefined
}

export const isShippingMethod = (option: any) => option && SHIPPING_METHODS.includes(option)
export const isCartItem = (item: any): item is CartItem => Reflect.has(item, 'quantity')

export interface ShippingMethod {
  id: ShippingMethodName
  title: string
  available: boolean
  errorMessage?: string
  price: number
  discountAmount: number
  deliveryDetails: string
  logo?: string
}

export interface QtyUpdateMessage {
  success: boolean
  message?: string
}

export interface CartItemOption {
  label: string
  value: string
  optionId: number
  optionValue: string
}

export interface CartItem {
  image: string | null
  id: string
  itemId: string
  name: string
  price: {
    original: number
    final: number
  }
  sections: Section[]
  quantity: number
  size: {
    value: string
    label: string
  }
  sku: string // variantSku
  parentSku: string
  stockStatus: StockStatus
  isGift: boolean
  taxCode?: string
  productDiscount?: number
  externalTaxAmount?: number
  variantId?: number
}

export interface CartToken {
  jwt: string
  date: string
}

export type CartTokens = {
  [key in Environment.StorefrontCode]?: CartToken
}

export interface UpdateItemQuantityData {
  cartItem: CartItem
  quantity: number
}

export interface CartMessage {
  type: 'error' | 'success' | 'warning' | 'notice'
  text: string
}
